.MuiTableRow-root>*{
    padding: 10px;    
}

.MuiTableRow-root.MuiTableRow-head>*{
    font-weight: bold !important;
    background-color: transparent;
}

.ContentTable td, th {
    border: none !important;

}

.Table :first-child{
    border-radius: 0rem;
}

