.CircularProgressbar-path{
stroke: white!important;
stroke-width: 12px!important;
filter: drop-shadow(2px 4px 6px white);
}

.CircularProgressbar{
    width: 4rem !important;
    overflow: visible;
}

.CircularProgressbar-trail{
    display: none;
}

.CircularProgressbar-text{
    fill: white!important;
}


